import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

const GeneralWrapper = styled.div`
  display: none;

  @media screen and (min-width: 800px) {
    display: block;
    min-height: 100vh;
    padding-top: calc(60px + 28px);
  }

  .introduction {
    color: var(--navy);
    font-family: var(--paragraph);
    margin-bottom: 14px;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .contact-form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    .form-text {
    }

    .form-inline-text {
      font-family: var(--paragraph);
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 14px;
      word-wrap: break-word;
    }

    .form-label-hidden {
      display: none;
    }

    .form-inline-input {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--navy);
      font-family: var(--paragraph);
      height: 20px;
      margin: 0px 0.5rem 14px;
      resize: horizontal;
      text-align: center;
    }

    .form-inline-input:active {
      width: auto;
    }

    .form-textarea-input {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--navy);
      font-family: var(--paragraph);
      margin-bottom: 14px;
      width: 100%;
    }

    .submit-wrapper {
      margin: 2rem 0;
      width: 100%;

      .special {
        background: transparent;
        border: none;
        font-family: var(--heading);
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }
    }
  }
`

const CO03General = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--dark-bg-blue)")
      setNavigation(3)
    }
  })

  return (
    <GeneralWrapper id="inquiry">
      <div ref={ref}></div>

      <form
        name="contact-inquiry"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact-form"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact-inquiry" />

        <p className="form-inline-text">Hi! I'm</p>
        <label className="form-label-hidden" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="your first and last name"
          className="form-inline-input"
          size="20"
        />
        <p className="form-inline-text">from</p>
        <label className="form-label-hidden" htmlFor="company">
          Company
        </label>
        <input
          type="text"
          name="company"
          id="company"
          placeholder="company name"
          className="form-inline-input"
        />
        <p className="form-inline-text">.</p>
        <p className="form-inline-text">I'm reaching out because</p>
        <label className="form-label-hidden" htmlFor="message">
          Message
        </label>
        <textarea
          type="textarea"
          name="message"
          id="message"
          placeholder=""
          rows="3"
          className="form-textarea-input"
        />

        <p className="form-inline-text">The best way to reach me is at</p>
        <label className="form-label-hidden" htmlFor="phone">
          Phone Number
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="phone"
          className="form-inline-input"
        />
        <div className="submit-wrapper">
          <input type="submit" value="Submit" className="special" />
        </div>
      </form>
      <p classname="form-"></p>
    </GeneralWrapper>
  )
}

export default CO03General
