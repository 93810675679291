import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

const TeamWrapper = styled.div`
  display: none;

  @media screen and (min-width: 800px) {
    display: block;
    min-height: 100vh;
    padding-top: calc(60px + 28px);
  }

  .introduction {
    color: var(--navy);
    font-family: var(--paragraph);
    margin-bottom: 14px;

    @media screen and (min-width: 800px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .contact-form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    .form-text {
    }

    .form-inline-text {
      font-family: var(--paragraph);
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 14px;
      word-wrap: break-word;
    }

    .form-label-hidden {
      display: none;
    }

    .form-inline-input {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--navy);
      font-family: var(--paragraph);
      height: 20px;
      margin: 0 0 14px;
      resize: horizontal;
      text-align: center;
    }

    .form-inline-input:active {
      width: auto;
    }
    .resume-upload {
      margin: 2rem 0;
      width: 100%;

      .form-inline-button {
        color: var(--navy);
        font-family: var(--paragraph);
        font-size: 20px;
      }
    }
    .submit-wrapper {
      margin: 2rem 0;
      width: 100%;

      .special {
        background: transparent;
        border: none;
        font-family: var(--heading);
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }
    }
  }
`

const CO02Team = ({ setBackground, setNavigation }) => {
  const THRESHOLD = [0, 0.5]
  const [ref, inView] = useInView({
    threshold: THRESHOLD,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView === true) {
      setBackground("var(--medium-bg-blue)")
      setNavigation(2)
    }
  })

  return (
    <TeamWrapper id="join">
      <div ref={ref}></div>
      <p className="introduction">
        We’re always on the look out for talented researchers, copywriters,
        designers, and so much more. We’d love to learn more about you. Please
        take a moment to tell us about yourself:
      </p>
      <p className="introduction">Hey, the Build team!</p>

      <form
        name="contact-team"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact-form"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact-team" />

        <p className="form-inline-text">I'm a</p>
        <label className="form-label-hidden" htmlFor="adjectives">
          Adjectives
        </label>
        <input
          type="text"
          name="adjectives"
          id="adjectives"
          placeholder="adjectives"
          className="form-inline-input"
          size="10"
        />
        <p className="form-inline-text">
          person, who's interested in working as a
        </p>
        <label className="form-label-hidden" htmlFor="role">
          Role
        </label>
        <input
          type="text"
          name="role"
          id="role"
          placeholder="role"
          className="form-inline-input"
        />
        <p className="form-inline-text">at The Build. My</p>
        <label className="form-label-hidden" htmlFor="experience">
          # Years Experience
        </label>
        <input
          type="text"
          name="experience"
          id="experience"
          placeholder="#"
          className="form-inline-input"
          size="3"
        />
        <p className="form-inline-text">years of</p>
        <p className="form-inline-text">work experience and my passion for</p>
        <label className="form-label-hidden" htmlFor="passions">
          Passions
        </label>
        <input
          type="text"
          name="passions"
          id="passions"
          placeholder=""
          className="form-inline-input"
          size="23"
        />
        <p className="form-inline-text">
          would make me a good fit at The Build. Most of all though, the
        </p>
        <p className="form-inline-text">
          reason I'm most excited to work with your team is
        </p>
        <label className="form-label-hidden" htmlFor="reasons">
          Reasons
        </label>
        <input
          type="text"
          name="reasons"
          id="reasons"
          placeholder=""
          className="form-inline-input"
          size="60"
        />
        <p className="form-inline-text">.</p>
        <p className="form-inline-text">The best way to reach me is at</p>
        <label className="form-label-hidden" htmlFor="phone">
          Phone Number
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="phone"
          className="form-inline-input"
        />
        <p className="form-inline-text">and</p>
        <label className="form-label-hidden" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="email"
          className="form-inline-input"
        />
        <p className="form-inline-text">.</p>
        <div className="resume-upload">
          <p className="form-inline-text">I've attached my resume here:</p>

          <input
            type="file"
            name="resume"
            id="resume"
            className="form-inline-button"
          />
        </div>

        <div className="submit-wrapper">
          <input type="submit" value="Submit" className="special" />
        </div>
      </form>
    </TeamWrapper>
  )
}

export default CO02Team
