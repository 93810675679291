import React from "react"
import styled from "styled-components"

const MobileWrapper = styled.div`
  margin-top: -85vh;
  padding: 0 1rem;
  @media screen and (min-width: 800px) {
    display: none;
  }

  .title {
    font-family: var(--subheading);
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .contact-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    .checkbox-table {
      display: flex;
      flex-direction: column;
    }
    .input-group {
      color: var(--navy);
      padding: 1rem 0;

      .form-label {
        font-family: var(--heading);
        font-size: 14px;
        line-height: 14px;
      }

      .form-input {
        background: transparent;
        border: none;
        border-bottom: 2px solid var(--navy);
        font-family: var(--paragraph);
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 28px;
        padding-top: 7px;
        width: 100%;
      }

      .form-checkbox-label {
        font-family: "Galano Grotesque Medium", sans-serif;
        font-size: 16px;
        line-height: 26px;
        margin: 0 0.5rem;
        text-transform: uppercase;
      }
    }

    .submit-wrapper {
      margin: 2rem 0;
      width: 100%;

      .special {
        background: transparent;
        border: none;
        font-family: var(--heading);
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }
    }
  }
`

const CO04Mobile = () => {
  return (
    <MobileWrapper>
      <p className="title">We want to hear from you</p>
      <form
        name="contact-general"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact-form"
        action="/success"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact-general" />
        <div className="input-group">
          <label className="form-label" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
            className="form-input"
          />
        </div>
        <div className="input-group">
          <label className="form-label" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Your email address"
            className="form-input"
          />
        </div>
        <div className="checkbox-table">
          <div className="input-group">
            <input
              type="checkbox"
              name="new-project"
              value="New Project"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="new-project">
              New Project
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="join-team"
              value="Joining Our Team"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="join-team">
              Joining Our Team
            </label>
          </div>
          <div className="input-group">
            <input
              type="checkbox"
              name="general"
              value="General"
              className="form-checkbox-input"
            />
            <label className="form-checkbox-label" htmlFor="general">
              General
            </label>
          </div>
        </div>

        <div className="submit-wrapper">
          <input type="submit" value="Submit" className="special" />
        </div>
      </form>
    </MobileWrapper>
  )
}

export default CO04Mobile
