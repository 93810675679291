import React, { useState } from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import CO01Projects from "./CO01Project"
import CO02Team from "./CO02Team"
import CO03General from "./CO03General"
import CO04Mobile from "./CO04Mobile"

const ContentWrapper = styled.section`
  background: ${props => props.background};
  padding-bottom: 50px;

  @media screen and (min-width: 800px) {
    background: unset;
    padding-bottom: 74px;
  }

  .background {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    height: 90vh;
    position: sticky;
    top: 50px;

    @media screen and (min-width: 800px) {
      background: ${props => props.background};
      top: 60px;
    }
    z-index: -1;
  }

  .navigation {
    margin-top: -85vh;
    position: sticky;
    top: 90px;
    width: 25%;
    @media screen and (max-width: 799px) {
      display: none;
    }

    .sidebar {
      list-style: none;

      #step01,
      #step02,
      #step03 {
        color: var(--navy);

        .link {
          color: var(--navy);
        }
      }

      @media screen and (min-width: 800px) {
        #step01 {
          color: ${props =>
            props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 1 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step02 {
          color: ${props =>
            props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 2 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }

        #step03 {
          color: ${props =>
            props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};

          .link {
            color: ${props =>
              props.navigation === 3 ? "var(--navy)" : "rgba(34, 42, 53, 0.3)"};
          }
        }
      }

      .nav-item {
        .nav-title {
          font-family: var(--subheading);
          font-size: 30px;
          line-height: 31px;
          text-transform: uppercase;
        }
      }
    }
  }

  .content {
    display: grid;

    @media screen and (min-width: 800px) {
      margin-top: -30vh;
      grid-template-columns: 1fr 1fr 2fr;
      padding: 0 7rem 0 0;
    }
  }
`

const CO00Content = () => {
  const [background, setBackground] = useState("var(--light-bg-blue)")
  const [navigation, setNavigation] = useState(1)

  return (
    <ContentWrapper navigation={navigation} background={background}>
      <Grid>
        <div className="background"></div>
        <div className="navigation">
          <ul className="sidebar">
            <li id="step01" className="nav-item">
              <a className="link" href="#projects">
                <h2 className="nav-title">New Project</h2>
              </a>
            </li>
            <li id="step02" className="nav-item">
              <a className="link" href="#join">
                <h2 className="nav-title">Joining Our Team</h2>
              </a>
            </li>
            <li id="step03" className="nav-item">
              <a className="link" href="#inquiry">
                <h2 className="nav-title">General Inquiry</h2>
              </a>
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="blank"></div>
          <div className="blank"></div>
          <div className="content-wrapper">
            <CO01Projects
              setBackground={setBackground}
              setNavigation={setNavigation}
              className="desktop-only"
            />
            <CO02Team
              setBackground={setBackground}
              setNavigation={setNavigation}
              className="desktop-only"
            />
            <CO03General
              setBackground={setBackground}
              setNavigation={setNavigation}
              className="desktop-only"
            />
            <CO04Mobile className="mobile-only" />
          </div>
        </div>
      </Grid>
    </ContentWrapper>
  )
}

export default CO00Content
