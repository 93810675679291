import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import PageHero from "../components/Layout/PageHero"
import CO00Content from "../components/Contact/CO00Content"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <PageHero
        title="Contact"
        description="Want to better understand your customer? Have a brand design challenge? Ready to transform your retail experience?
        Fill in the blanks below and we’ll be in touch."
        videoUrl="//videos.ctfassets.net/d0u2w5k6pl1p/2l85zYq9HKWHnJfU6PZEet/c9ffd948afac9cd13a73ca71d6ac076c/Contact.mp4"
      />
      <CO00Content />
    </Layout>
  )
}

export default ContactPage
